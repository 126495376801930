import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { parse } from 'query-string'

import { authService, routes } from '@unicaiot/unica-iot-gallery-core'

export const AdRedirectView: FunctionComponent<RouteComponentProps> = params => {
    const tokenParams = parse(params.location.hash)?.access_token

    const token = Array.isArray(tokenParams) ? tokenParams[0] : tokenParams

    const history = useHistory()

    useEffect(() => {
        ;(async function () {
            try {
                if (!token) {
                    throw new Error('Token is null')
                }

                const authResult = await authService.postToken(token)

                if (authResult.status === 200) {
                    if (history.action !== 'POP') {
                        history.goBack()
                    } else {
                        history.push(routes.index)
                    }
                } else {
                    history.push(routes.login)
                }
            } catch {
                history.push(routes.login)
            }
        })()
    }, [token, history])

    return <Fragment />
}
