import { ResponsePromise, client } from '@unicaiot/unica-iot-gallery-core'

import { Preference, PreferenceKey } from './types'
import { config } from '../../../../config'

export interface IPreferenceService {
    getCombinedPreference: <T>(userId: string, key: PreferenceKey) => ResponsePromise<Preference<T>>
    getGlobalPreference: <T>(key: PreferenceKey) => ResponsePromise<Preference<T>>
    getTenantPreference: <T>(key: PreferenceKey) => ResponsePromise<Preference<T>>
    getUserPreference: <T>(userId: string, key: PreferenceKey) => ResponsePromise<Preference<T>>
}

export const preferenceService: IPreferenceService = {
    getCombinedPreference<T>(userId: string, key: PreferenceKey) {
        return client.post<Preference<T>>(config.preferences.preferencesCombined(userId), [key])
    },
    getGlobalPreference<T>(key: PreferenceKey) {
        return client.post<Preference<T>>(config.preferences.preferencesGlobal, [key])
    },
    getTenantPreference<T>(key: PreferenceKey) {
        return client.post<Preference<T>>(config.preferences.preferencesTenant, [key])
    },
    getUserPreference<T>(userId: string, key: PreferenceKey) {
        return client.post<Preference<T>>(config.preferences.preferencesUser(userId), [key])
    },
}
