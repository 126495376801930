import { Preference, PreferenceKey } from './types'
import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'
import { preferenceService } from './service'

export const useGetCombinedPreference = <T>(
    userId?: string,
    key?: PreferenceKey
): RequestStatus<Preference<T> | undefined> => {
    const getProsmise = () => (userId && key ? preferenceService.getCombinedPreference<T>(userId, key) : undefined)

    return useRequest(getProsmise, [userId, key])
}

export const useGetUserPreference = <T>(
    userId?: string,
    key?: PreferenceKey
): RequestStatus<Preference<T> | undefined> => {
    const getProsmise = () => (userId && key ? preferenceService.getUserPreference<T>(userId, key) : undefined)

    return useRequest(getProsmise, [userId, key])
}

export const useGetGlobalPreference = <T>(key?: PreferenceKey): RequestStatus<Preference<T> | undefined> => {
    const getProsmise = () => (key ? preferenceService.getGlobalPreference<T>(key) : undefined)

    return useRequest(getProsmise, [key])
}

export const useGetTenantPreference = <T>(key?: PreferenceKey): RequestStatus<Preference<T> | undefined> => {
    const getProsmise = () => (key ? preferenceService.getTenantPreference<T>(key) : undefined)

    return useRequest(getProsmise, [key])
}
