import styles from './DashboardLayout.module.scss'

import React from 'react'
import classNames, { Argument } from 'classnames'

import { Footer } from '../Footer/Footer'
import { ActivityIndicator, ActivityIndicatorEnumType } from '@unicaiot/unica-iot-gallery-core'
import { LayoutType } from '../../Types/Types'

interface Props {
    className?: Argument
    pageType?: LayoutType
    showFooter?: boolean
    showHeader?: boolean
    loading?: boolean
}

export const DashboardLayout: React.FunctionComponent<Props> = props => {
    const { children, pageType, loading } = props

    const currentPageType = pageType || LayoutType.light

    return (
        <div
            className={classNames(styles.container, {
                [styles.light]: currentPageType === LayoutType.light,
                [styles.dark]: currentPageType === LayoutType.dark,
                [styles.showFooter]: !!props.showFooter,
                [styles.showHeader]: !!props.showHeader,
            })}
        >
            <div className={classNames(styles.wrapper, props.className)}>
                {loading ? (
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                ) : (
                    children
                )}
            </div>
            {props.showFooter && <Footer />}
        </div>
    )
}
